import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?lang=cz&paged=14
// http://www.dktech.cz/?p=125

const PostBudoucnost: FC = () => {
  return (
    <PageTemplate
      prevLink="/post_c4"
      nextLink="/post_vanocni_c4"
    >
      <PostHeader
        title="Hlasování o budoucnosti DKTech serveru"
        link="/post_c4"
        author="Algy"
        date="31.12.2013"
        time="10:44"
        category="Oznámení"
      />
      <p style={{ textAlign: 'justify' }}>Zdravím,</p>
      <p style={{ textAlign: 'justify' }}>v návaznosti na spuštění vánoční C4 a následně rozpoutané diskuze na našem fóru, viz.&nbsp;<a href="https://dktech.cz/forum/index.php/topic,29.0.html">https://dktech.cz/forum/index.php/topic,29.0.html</a>, vedení serveru rozhodlo o tom, že vyslyší hlas Vás, hráčů, kteří tvoříte jedinečnou komunitu kolem serveru DKTech. Komunitu natolik skvělou a příjemnou, že má cenu pro ní obětovat hodiny volného času.</p>
      <p style={{ textAlign: 'justify' }}>Již několik dní v Giranu na náměstí sedí a žádá o svou pozornost několik herních postav. Ptali jsme se jich a oni nám řekli, že si přejí, aby stará dobrá Cé-čtyřka běžela na DKTechu na stálo. Vážíme si ale i hráčů, kteří do posledních chvil setrvali na Gracii Final. Uvědomujeme si, že jsme jim přislíbili po přestěhování serveru spustit jejich chronicle a probudit vypiplané postavy ze zimního nedobrovolného spánku. &nbsp;Spuštění Grácie se ale z námi nezaviněných důvodů odložilo na dobu neurčitou a rozhodně dobu přesahující vánoční svátky a tedy i zimní prázdniny. Zrodil se tedy nápad Vám na zkrácení dlouhých zimních večerů alespoň dočasně spustit C4 s původním nastavením. To jsme ovšem netušili, co to způsobí. A musím přiznat, že pro mě to překvapení bylo o to milejší, když se do diskuzí zapojili i lidé, kteří hraní na DKTechu (tehdy ještě Gameparku) opustili někdy v roce 2011.</p>
      <p style={{ textAlign: 'justify' }}>Přátelé, svoji aktivitou jste prokázali, že náš (tím myslím nás – všechny hráče, nikoliv nás – vedení) server může mít světlou a skvělou budoucnost s množstvím aktivních postav tvořících nové příběhy. Budoucnost, která nám přinese zase množství probdělých nocí u farmení a zdolávání bezpočtu Raidbossů. Vědomi si všech důsledků a s velkou omluvou na rtech teď porušíme slib daný hráčům Grácie a o dalším směrování DKTechu Vás necháme rozhodnout v hlasování.</p>
      <p style={{ textAlign: 'justify' }}>Rozhodli jsme se na facebookových stránkách ve skupině spojené s naším serverem, kterou naleznete zde&nbsp;<a href="https://www.facebook.com/groups/49632123538/">https://www.facebook.com/groups/49632123538/</a>&nbsp;vytvořit hlasování a nabídnout Vám tři varianty. Pokusím se Vám nyní vysvětlit proč tři a proč na facebooku.&nbsp;<a href="https://www.facebook.com/groups/49632123538/"><br />
      </a></p>
      <p style={{ textAlign: 'justify' }}>Anketu na facebooku jsme zvolili proto, že je přece jen o něco hůře zmanipulovatelná, než třeba ta na fóru. A validita dat má pro nás větší význam, než snadný přístup k hlasování. Uvědomujeme si, že ne každý má svůj profil na facebooku. Chcete-li ale, aby byl tentokrát Váš hlas slyšet, založte se profil nebo hlasujte z profilu Vašich známých či blízkých.</p>
      <p style={{ textAlign: 'justify' }}>A proč tři možnosti, tedy GF bez wipe, GF s wipe a C4 tak jak běží? Uvedené chronicle jsme schopni spustit bez větších zásahů adminů. Existují k nim ochrany proti různým botům a podobně. A jsou odzkoušené. Gracia Final by tedy mohla běžet po zajištění virtuálního serveru a zakoupení změny licence tak, jak běžela doposud. Uvědomujeme si ale, že bez wipe mnoho nových hráčů na server nedorazí a s rukou na srdci přiznáváme, že počet hráčů ke konci GF byl tristní. Přesto, vědomi si svého slibu, nabízíme i možnost bez wipe. GF je ovšem chronicle nabízející velkou spoustu nových možností oproti C4. Proto je dána možnost spustit znovu GF po wipe. Ať tak či onak, s rate na GF by se nehýbalo a zůstaly by dynamické tak, jako tomu bylo doposud. A konečně žádaná C4. Zde jsme se po zralé úvaze a na základě vašich četných názorů rozhodli pro zachování rate na 2x. A jako odměnu pro ty, kteří se zasloužili o to, aby se tato diskuze vůbec otevřela, jsme se rozhodli už žádný další wipe nedělat. Pokud si zvolíte tuto možnost, nic se mazat nebude a hra poběží tak, jak se rozeběhla 25. prosince 2013. Každý měl možnost hrát od samého začátku.</p>
      <p style={{ textAlign: 'justify' }}>Tyto tři možnosti se nebudou měnit. Diskuze o změnách rate nebo jiných chronicle je tedy bezpředmětná! Hlasování ukončíme ve chvíli, kdy budeme mít potvrzený virtuál server a tudíž by případnému spuštění GF nic nebránilo. Byť si nemyslím, že bychom toho kdy využili, musím to uvést: vyhrazujeme si právo rozhodnout jinak, než v souladu s výsledky hlasování.</p>
      <p style={{ textAlign: 'justify' }}>
      </p><p style={{ textAlign: 'justify' }}>Závěrem bych Vám chtěl za vedení DKTech serveru popřát &nbsp;dobrou volbu, spousty hodin zábavného hraní, v roce 2014 spousty osobních a pracovních/studijních úspěchů a především pevné zdraví.</p>
      <p style={{ textAlign: 'justify' }}>
      </p><p style={{ textAlign: 'justify' }}>GM Algy</p>
      <p>&nbsp;</p>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default PostBudoucnost

export { Head } from '../components/defaultHtmlHead'